
		<header class="main-header">

			<nav class="navbar navbar-static-top" role="navigation">
			<span class="logo">
			<span class="logo-mini">OST</span>
			<span class="logo-lg">
			<strong>One</strong>Support
			</span>
		</span>
			</nav>
		</header>
		<div class="center-me">
			<a class="link-container" (click)="launchPhone()"><i class="fa fa-phone" style="
    display: block;
    text-align: center;
    font-size: 3em;"></i>Launch Phone</a>
			<a href="realtime" target="_blank" class="link-container"><i class="fa fa-tachometer" style="
    display: block;
    text-align: center;
    font-size: 3em;"></i>Realtime</a>
			<a href="reports" target="_blank" class="link-container"><i class="fa icon-bar-chart" style="
    display: block;
    text-align: center;
    font-size: 3em;"></i>Reports</a>
		</div>
		<!--<div class="center-me"><button class="btn btn-primary btn-lg" (click)="launchPhone()">Launch Phone</button></div>
		<div class="center-me"><button *ngIf="showAgentViewButton" class="btn btn-default btn-sm" (click)="launchAgentPhone()">Launch Agent Phone</button></div>-->
	