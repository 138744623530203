import {Component, ElementRef, OnInit} from '@angular/core';
import {AppErrorHandler, JsonWebToken} from '@tni/core';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {debounceTime, filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {AuthService} from '@tni/core';
import {PageControlService} from '../Service/PageControlService';

@Component({
	selector: 'portal',
	templateUrl: 'templates/portal.template.html',
	providers: [PageControlService]
})
export class AppComponent implements OnInit {
	public showNavigationObs: Observable<boolean>;

	constructor(public _token: JsonWebToken, private _pageControlService: PageControlService, private _elementRef: ElementRef<HTMLBodyElement>,
				private _router: Router, private authService: AuthService) {
		this.authService.runInitialLoginSequence();
	}

	private _minimizedSidebarSubscription: Subscription;
	private _toggleSidebarSubscription: Subscription;
	private _showNavigationSubscription: Subscription;
	private _toggleSidebarSubj: Subject<void> = new Subject<void>();
	private _routerSubscription: Subscription;

	ngOnInit(): void {
		/*this.showNavigationObs = this._pageControlService.hideAllNavigationObservable.pipe(
			map((h: boolean) => !h),
			// TODO: remove next line when all sub components converted to onPush and make changeDetection here OnPush. Temp fix for changeAfterDetection error
			debounceTime(50),
			shareReplay(1));
		this._minimizedSidebarSubscription = this._pageControlService.sidebarMinimizedObservable.subscribe((m: boolean) => {
			if (this._elementRef) {
				if (m) {
					this._elementRef.nativeElement.classList.add('sidebar-collapse');
				} else {
					this._elementRef.nativeElement.classList.remove('sidebar-collapse');
				}
			}
		});

		this._showNavigationSubscription = this.showNavigationObs.subscribe((s: boolean) => {
			if (this._elementRef) {
				if (s) {
					this._elementRef.nativeElement.classList.add('sidebar-mini', 'fixed');
					this._elementRef.nativeElement.classList.remove('layout-top-nav');
				} else {
					this._elementRef.nativeElement.classList.remove('sidebar-mini', 'fixed');
					this._elementRef.nativeElement.classList.add('layout-top-nav');
				}
			}
		});

		this._toggleSidebarSubscription = this._toggleSidebarSubj.asObservable().pipe(
			switchMap(() => this._pageControlService.sidebarMinimizedObservable.pipe(take(1)))
		).subscribe((currentMinimizeStatus: boolean) => this._pageControlService.minimizeSidebar = !currentMinimizeStatus);
*/
		this._routerSubscription = this._router.events.pipe(
			filter((ev: RouterEvent) => ev instanceof NavigationStart)
		).subscribe(() => AppErrorHandler.clearEvents());
	}

	ngOnDestroy(): void {
		if (this._routerSubscription) {this._routerSubscription.unsubscribe(); }
		this._toggleSidebarSubj.complete();
		if (this._minimizedSidebarSubscription) {
			this._minimizedSidebarSubscription.unsubscribe();
		}
		if (this._toggleSidebarSubscription) {
			this._toggleSidebarSubscription.unsubscribe();
		}
		if (this._showNavigationSubscription) {
			this._showNavigationSubscription.unsubscribe();
		}
	}

	public toggleSidebar(): void {
		this._toggleSidebarSubj.next();
	}
}
