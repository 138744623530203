import {ErrorHandler, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DefaultUrlSerializer, UrlTree, Routes} from '@angular/router';
import {PhoneWidget} from './Widget/Phone/PhoneWidget';
import {LaunchComponent} from './Components/LaunchComponent';
import {AppErrorHandler, CanActivateJwt} from '@tni/core';
import {LoggerReportWidget} from './Widget/LoggerReport/LoggerReportWidget';
import {ContactCenterAdministrationConsoleWidget} from './Widget/ContactCenterAgentAdmin/ContactCenterAgentAdminComponent';
import {AuthGuardWithForcedLogin, AuthCoreModule} from '@tni/core';

export const AppRoutes: Routes = [
	{
		path: 'login', component: LaunchComponent, canActivate: [AuthGuardWithForcedLogin]
	},
	{
		path: 'should-login', component: LaunchComponent
	},
	{
		path: '', component: LaunchComponent,  canActivate: [AuthGuardWithForcedLogin]
	},
	{
		path: 'phone',
		component: PhoneWidget,
		canActivate: [AuthGuardWithForcedLogin]
	},
	{
		path: 'loggerreport',
		component: LoggerReportWidget,
		canActivate: [CanActivateJwt]
	},
	{
		path: 'contactcenter/agent/:agentId/administration',
		component: ContactCenterAdministrationConsoleWidget,
		canActivate: [CanActivateJwt]
	},
];

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	parse(url: string): UrlTree {
		let normal: UrlTree = super.parse(url);
		let lower: UrlTree = super.parse(url.toLowerCase());
		let ret: UrlTree = new UrlTree();
		ret.root = lower.root;
		ret.queryParams = normal.queryParams;
		ret.fragment = normal.fragment;
		return ret;
	}
}


@NgModule({
	imports: [RouterModule.forRoot(AppRoutes, {useHash: true}), AuthCoreModule.forRoot()],
	exports: [RouterModule],
	providers: [{
		provide: ErrorHandler,
		useClass: AppErrorHandler
	},
	]
})
export class RoutesModule {
}
