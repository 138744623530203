import {Component} from '@angular/core';
import {JsonWebToken} from '@tni/core';

@Component({
	selector: 'launch',
	template: `
		<header class="main-header">

			<nav class="navbar navbar-static-top" role="navigation">
			<span class="logo">
			<span class="logo-mini">OST</span>
			<span class="logo-lg">
			<strong>One</strong>Support
			</span>
		</span>
			</nav>
		</header>
		<div class="center-me">
			<a class="link-container" (click)="launchPhone()"><i class="fa fa-phone" style="
    display: block;
    text-align: center;
    font-size: 3em;"></i>Launch Phone</a>
			<a href="realtime" target="_blank" class="link-container"><i class="fa fa-tachometer" style="
    display: block;
    text-align: center;
    font-size: 3em;"></i>Realtime</a>
			<a href="reports" target="_blank" class="link-container"><i class="fa icon-bar-chart" style="
    display: block;
    text-align: center;
    font-size: 3em;"></i>Reports</a>
		</div>
		<!--<div class="center-me"><button class="btn btn-primary btn-lg" (click)="launchPhone()">Launch Phone</button></div>
		<div class="center-me"><button *ngIf="showAgentViewButton" class="btn btn-default btn-sm" (click)="launchAgentPhone()">Launch Agent Phone</button></div>-->
	`,
	styles: [`
		:host {
			display: flex;
			align-content: center;
			flex-direction: column;
		}
		.center-me {
			display: flex;
			justify-content: center;
		}
		.link-container {
			border: 1px solid #9e9797;
			border-radius: 4px;
			padding: 8px;
			margin-left: 12px;
			width: 120px;
			text-align: center;
			display: block;
			margin-top: 5vh;
		}
		.link-container:hover {
		 cursor: pointer;
		}
		button {
			margin-top: 5vh;
		}
	`]
})
export class LaunchComponent {
	constructor(private _jwt: JsonWebToken) {}

	public showAgentViewButton: boolean;

	ngOnInit(): void {
		this.showAgentViewButton = this._jwt.CanAction('ViewAll', 4018);
	}

	public launchAgentPhone(): void {
		window.open(
			window.location.href + 'phone?view=agent',
			'phoneControl',
			'height=600,width=375,resizable,scrollbars=no,status=no,menubar=no,toolbar=no,location=no'
		);
	}

	public launchPhone(): void {
		window.open(
			window.location.origin + '/#/phone',
			'phoneControl',
			'height=600,width=375,resizable,scrollbars=no,status=no,menubar=no,toolbar=no,location=no'
		);
	}
}
