import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class PageControlService {
	public sidebarMinimizedObservable: Observable<boolean>;
	private _sidebarMinimizedSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
	public set minimizeSidebar(m: boolean) {
		this._sidebarMinimizedSubject.next(m);
	}

	public hideAllNavigationObservable: Observable<boolean>;
	private _hideAllNavigationSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
	public set hideAllNavigation(h: boolean) {
		this._hideAllNavigationSubject.next(h);
	}

	constructor() {
		this.sidebarMinimizedObservable = this._sidebarMinimizedSubject.asObservable().pipe(shareReplay(1));
		this.hideAllNavigationObservable = this._hideAllNavigationSubject.asObservable().pipe(shareReplay(1));
	}
}
